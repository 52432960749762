.footer-links ul {
    padding: 0;
    margin: 0;
}

.footer-links ul li {
    font-family: 'ProximaNovaSemibold';
    list-style-type: none;
}

.footer-links ul li a {
    text-decoration: none;
    color: #093553;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
}

.footer-links ul li a:hover {
    color: #337ab7;
}

.footer-top-inner {
    text-align: center;
}

.footer-logo {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
}

.footer-logo a { 
    display: block;
}

.footer-top {
    background-color: #f2f5f7;
    padding: 30px 0 50px;
}

.footer-bottom {
    text-align: center;
    padding: 13px 0;
}

.footer-bottom p {
    font-size: 16px;
    line-height: 20px;
    margin: 0;
}

/* Responsive Start */
@media (min-width: 992px) and (max-width: 1199px) {
    .footer-logo {max-width: 300px;}
}

@media (min-width: 768px) and (max-width: 991px) {
    .footer-logo {max-width: 300px;}
    .footer-top { padding: 20px 0 40px; }
}

@media (max-width: 991px) {
    .footer-logo {max-width: 220px;}
    .footer-top { padding: 20px 0 40px; }
}
/* Responsive End */