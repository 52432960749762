.step-three-wrp {
    padding: 80px 0 0;
    position: relative;
    z-index: 2;
}

.step-three-content {
    color: #fff;
}

.step-three-content .main-title {
    margin: 0 0 20px;
}

.step-three-content .common-detail {
    margin: 0 0 30px;
}

.step-three-img {
    margin: -50px 0 -50px -180px;
}

/* Responsive Start */
@media (min-width: 1600px) and (max-width: 1699px) {
    .step-three-img { margin: 0 0 -50px -110px; }
}

@media (min-width: 1500px) and (max-width: 1599px) {
    .step-three-img { margin: 0 0 -50px -70px; }
}

@media (min-width: 1400px) and (max-width: 1499px) {
    .step-three-img { margin: 0 0 -40px -20px; }
}

@media (min-width: 1300px) and (max-width: 1399px) {
    .step-three-img { margin: 0 0 -40px -20px; }
}

@media (min-width: 1200px) and (max-width: 1299px) {
    .step-three-img { margin: 0; }
    .step-three-wrp { padding: 80px 0 20px; }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .step-three-img { margin: 0; }
    .step-three-wrp { padding: 60px 0 60px; }
}

@media (min-width: 768px) and (max-width: 991px) {
    .step-three-img { margin: 0; max-width: 500px; width: 100%; margin: 0 auto -40px; }
    .step-three-wrp { padding: 60px 0 0; }
    .step-three-main .row {flex-direction: column-reverse;}
    .step-three-content {margin: 0 0 10px;}
}

@media (max-width: 767px) {
    .step-three-img { margin: 0; max-width: 500px; width: 100%; margin: 0 auto -40px; }
    .step-three-wrp { padding: 40px 0 0; background-attachment: scroll !important; background-position: center !important; }
    .step-three-main .row {flex-direction: column-reverse;}
    .step-three-content {margin: 0 0 30px;}
    .step-three-content .main-title { margin: 0 0 12px; }
    .step-three-content .common-detail { margin: 0 0 25px; }
}
/* Responsive End */