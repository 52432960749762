.step-two-content .main-title {
    margin: 0 0 15px;
}

.step-two-img {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
}

.step-two-wrp {
    padding: 20px 0;
}


/* Responsive Start */
@media (min-width: 1300px) and (max-width: 1399px) {
    .step-two-img { max-width: 300px;}
}

@media (min-width: 1200px) and (max-width: 1299px) {
    .step-two-img { max-width: 300px;}
}

@media (min-width: 992px) and (max-width: 1199px) {
    .step-two-img { max-width: 200px;}
}

@media (min-width: 768px) and (max-width: 991px) {
    .step-two-wrp { padding: 60px 0 40px; }
    .step-two-img { max-width: 160px; }
}

@media (max-width: 767px) {
    .step-two-wrp { padding: 60px 0 40px; }
    .step-two-img { max-width: 160px; }
    .step-two-content {margin: 0 0 30px;}
}
/* Responsive End */