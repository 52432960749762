.contact-title {
    text-align: center;
    color: #fff;
    margin: 0 0 50px;
}

.contact-detail ul {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-detail ul li {
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 30px 0 0;
}

.contact-detail ul li:last-child {
    padding-right: 0;
}

.contact-detail ul li a {
    color: #fff;
}

.contact-detail ul li a:hover {
    color: #7EBC42;
}

.contact-detail ul li span {
    font-size: 16px;
    line-height: 16px;
    width: 30px;
    height: 30px;
    color: #7EBC42;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 0 0;
    border: 2px solid #fff;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.contact-wrp {
    padding: 100px 0 80px;
}

.contact-title .main-title {
    margin: 0 0 10px;
}

.contact-title .common-detail {
    max-width: 626px;
    width: 100%;
    margin: 0 auto 30px;
}

.contact-form {
    max-width: 608px;
    width: 100%;
    margin: 0 auto;
}

.common-form .form-label {
    font-family: 'ProximaNovaBold';
    color: #fff;
    margin: 0 0 10px;
}

.common-form .form-label small {
    font-family: 'ProximaNovaReg';
    font-size: 13px;
    line-height: 15px;
    font-style: italic;
    margin: 0 0 0 10px;
}

.common-form .form-control {
    box-shadow: none;
    border: none;
    padding: 15px;
    padding: 10px 15px;
    resize: none;
}

.common-form .form-group {
    margin: 0 0 30px;
}

/* Responsive Start */
@media (min-width: 992px) and (max-width: 1199px) {
    .contact-wrp { padding: 60px 0 60px; }
}

@media (min-width: 768px) and (max-width: 991px) {
    .contact-wrp { padding: 60px 0 60px; }
    .contact-title { margin: 0 0 30px; }
}

@media (max-width: 767px) {
    .contact-wrp { padding: 40px 0 20px; }
    .contact-title { margin: 0 0 20px; }
    .contact-title .common-detail { margin: 0 auto 15px; }
    .contact-detail ul {flex-direction: column;}
    .contact-detail ul li {padding: 0 0 15px;}
    .common-form .form-group { margin: 0 0 20px; }
}
/* Responsive End */