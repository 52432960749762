.step-one-content {
    color: #fff;
}

.step-one-wrp {
    padding: 80px 0 0;
}

.step-one-img {
    margin: 0 0 -70px;
}

.step-one-block .main-title {
    margin: 0 0 20px;
}

.step-one-block {
    margin: 0 0 40px;
}

/* Responsive Start */
@media (min-width: 992px) and (max-width: 1199px) {
    .step-one-wrp { padding: 60px 0 0; }
    .step-one-block { margin: 0 0 30px; }
    .step-one-block .main-title { margin: 0 0 12px; }
    .step-one-img { margin: 0 0 0; }
}

@media (min-width: 768px) and (max-width: 991px) {
    .step-one-wrp { padding: 60px 0 0; }
    .step-one-block { margin: 0 0 30px; }
    .step-one-block .main-title { margin: 0 0 12px; }
    .step-one-main .row {flex-direction: column-reverse;}
    .step-one-img {max-width: 400px; width: 100%; margin: 0 auto -40px;}
    .step-one-content {padding: 0 0 10px;}
}

@media (max-width: 767px) {
    .step-one-wrp { padding: 60px 0 0; }
    .step-one-block { margin: 0 0 30px; }
    .step-one-block .main-title { margin: 0 0 12px; }
    .step-one-main .row {flex-direction: column-reverse;}
    .step-one-img {max-width: 400px; width: 100%; margin: 0 auto -40px;}
    .step-one-content {padding: 0 0 10px;}
}
/* Responsive End */