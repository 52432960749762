@font-face {
    font-family: 'ProximaNovaReg';
    src: url('ProximaNovaReg.eot');
    src: url('ProximaNovaReg.eot') format('embedded-opentype'),
         url('ProximaNovaReg.woff2') format('woff2'),
         url('ProximaNovaReg.woff') format('woff'),
         url('ProximaNovaReg.ttf') format('truetype'),
         url('ProximaNovaReg.svg#ProximaNovaReg') format('svg');
}

@font-face {
    font-family: 'ProximaNovaSemibold';
    src: url('ProximaNovaSemibold.eot');
    src: url('ProximaNovaSemibold.eot') format('embedded-opentype'),
         url('ProximaNovaSemibold.woff2') format('woff2'),
         url('ProximaNovaSemibold.woff') format('woff'),
         url('ProximaNovaSemibold.ttf') format('truetype'),
         url('ProximaNovaSemibold.svg#ProximaNovaSemibold') format('svg');
}

@font-face {
    font-family: 'ProximaNovaBold';
    src: url('ProximaNovaBold.eot');
    src: url('ProximaNovaBold.eot') format('embedded-opentype'),
         url('ProximaNovaBold.woff2') format('woff2'),
         url('ProximaNovaBold.woff') format('woff'),
         url('ProximaNovaBold.ttf') format('truetype'),
         url('ProximaNovaBold.svg#ProximaNovaBold') format('svg');
}
