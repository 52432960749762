.header {
    border-bottom: 1px solid #f2f5f7;
    padding: 27px 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    background-color: #fff;
}

.header .navbar-brand {
    max-width: 350px;
    width: 100%;
    padding: 0;
    margin: 0;
}

.header .navbar {
    padding: 0;
}

.header .nav-link {
    font-family: 'ProximaNovaSemibold';
    font-size: 16px;
    line-height: 18px;
    padding: 0 !important;
    margin: 0 20px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #005487;
    cursor: pointer;
}

.header .nav-link:last-child {
    margin-right: 0;
}

.header .nav-link:hover,
.header .nav-link.active {
    color: #083553;
}

/* Responsive Start */
@media (min-width: 992px) and (max-width: 1199px) {
    .header .navbar-brand { max-width: 280px; }
}

@media (min-width: 768px) and (max-width: 991px) {
    .header .navbar-brand { max-width: 200px; }
    .header .nav-link {margin: 0 10px;}
}

@media (max-width: 767px) {
    .header .navbar-brand { max-width: 200px; }
    .header .nav-link {margin: 15px 0;}
    .header { padding: 20px 0; }
    .header .navbar-toggler {padding: 0; border: none; box-shadow: none; background-image: none; z-index: 9; width: 21px; height: 18px;}
    .header .navbar-toggler-icon { background-color: #005487; background-image: none; display: block; height: 2px; position: relative; transform: rotate(45deg); -webkit-transform: rotate(0deg); -moz-transform: rotate(45deg); -ms-transform: rotate(45deg); -o-transform: rotate(45deg); transition: 0.5s; -webkit-transition: 0.5s; -moz-transition: 0.5s; -ms-transition: 0.5s; -o-transition: 0.5s; width: 21px; }
    .header .navbar-toggler-icon:before { top: -7px; transform: rotate(0deg); -webkit-transform: rotate(0deg); -moz-transform: rotate(0deg); -ms-transform: rotate(0deg); -o-transform: rotate(0deg); }
    .header .navbar-toggler-icon:after { bottom: -5px; opacity: 1; }
    .header .navbar-toggler:not(.collapsed) .navbar-toggler-icon { transform: rotate(45deg); -webkit-transform: rotate(45deg); -moz-transform: rotate(45deg); -ms-transform: rotate(45deg); -o-transform: rotate(45deg); }
    .header .navbar-toggler-icon:after, .header .navbar-toggler-icon:before { background-color: #005487; content: ""; display: block; height: 2px; position: relative; width: 21px; }
    .header .navbar-toggler:not(.collapsed) .navbar-toggler-icon:before { top: 0; transform: rotate(90deg); -webkit-transform: rotate(90deg); -moz-transform: rotate(90deg); -ms-transform: rotate(90deg); -o-transform: rotate(90deg); }
    .header .navbar-toggler:not(.collapsed) .navbar-toggler-icon:after { bottom: -5px; opacity: 0; }
    .header .navbar-collapse { text-align: center;background-color: #fff; bottom: 0; height: 100%; max-width: 100%; overflow: auto; padding: 80px 40px; position: fixed; right: -100%; top: 0; transition: .5s; -webkit-transition: .5s; -moz-transition: .5s; -ms-transition: .5s; -o-transition: .5s; width: 100%; }
    .header .navbar-collapse.show { height: auto; right: 0; }
}
/* Responsive End */