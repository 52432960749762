.benefits-content .block-title {
    position: relative;
    padding: 0 0 0 40px;
}

.benefits-content .block-title::before {
    content: "";
    background-image: url("../../../assets/images/checkmark.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 36px;
    height: 26px;
    position: absolute;
    left: 0;
    top: 6px;
}

.benefits-details .common-detail ul {
    padding-left: 60px;
}

.benefits-details {
    margin: 0 0 20px;
}

.benefits-content .main-title {
    margin: 0 0 20px;
}

.benefits-wrp {
    padding: 100px 0 80px;
    position: relative;
    z-index: 1;
}

.benefits-wrp::after {
    content: "";
    background-color: rgba(255,255,255,0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

/* Responsive Start */
@media (min-width: 992px) and (max-width: 1199px) {
    .benefits-wrp { padding: 60px 0 60px; }
}

@media (min-width: 768px) and (max-width: 991px) {
    .benefits-wrp { padding: 60px 0 50px; background-position: 70% 0% !important; }
    .benefits-content .block-title::before {width: 32px; height: 22px;}
}

@media (max-width: 767px) {
    .benefits-wrp { padding: 60px 0 20px; }
    .benefits-content .block-title::before {width: 32px; height: 22px;}
}
/* Responsive End */