.banner-img img {
    max-width: 350px;
    width: 100%;
    margin: 0 auto;
}

.banner-content .main-title {
    margin: 0 0 20px;
}

.banner-content .common-detail {
    margin: 0 0 30px;
}

.banner-wrp {
    padding: 100px 0 90px;
    margin: 116px 0 0;
}

/* Responsive Start */
@media (min-width: 992px) and (max-width: 1199px) {
    .banner-img img { max-width: 300px; }
    .banner-img {text-align: center;}
}

@media (min-width: 768px) and (max-width: 991px) {
    .banner-wrp { padding: 60px 0 60px; margin: 90px 0 0; }
    .banner-img img { max-width: 280px; }
    .banner-img {text-align: center;}
}

@media (max-width: 767px) {
    .banner-wrp { padding: 40px 0 40px; margin: 78px 0 0; background-image: none !important; }
    .banner-content .common-detail { margin: 0 0 20px; }
    .banner-main .row {flex-direction: column-reverse;}
    .banner-img {margin: 0 0 30px;}
}
/* Responsive End */