body {
  font-family: 'ProximaNovaReg';
  font-size: 20px;
  line-height: 30px;
  color: #093553;
}

a {
  text-decoration: none;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

/* Common Style Start */
.main-title {
  font-family: 'ProximaNovaBold';
  font-size: 72px;
  line-height: 80px;
}

.main-title span {
  color: #7EBC42;
}

.main-title * {
  text-decoration: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

.block-title {
  font-family: 'ProximaNovaBold';
  font-size: 36px;
  line-height: 40px;
}

.block-title * {
  text-decoration: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

.common-detail * {
  margin: 0;
}

.btn {
  font-size: 20px;
  line-height: 22px;
  padding: 18px 30px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.btn-blue {
  color: #fff;
  background-color: #083553;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.btn-blue span {
  display: block;
  margin: 0 0 0 12px;
  font-size: 26px;
  line-height: 26px;
}

.btn-blue:hover,
.btn-blue:focus,
.btn-blue:active {
  color: #fff !important;
  background-color: #005487 !important;
}

.btn-blue:active {
  position: relative;
  top: 1px;
}

.btn-green {
  font-family: 'ProximaNovaSemibold';
  background-color: #7EBC42;
  border: none;
}

.btn-green:hover,
.btn-green:focus,
.btn-green:active {
  background-color: #005487 !important;
  border: none !important;
}
/* Common Style End */


/* Responsive Start */
@media (min-width: 1400px) {
  .container {max-width: 1324px;}
}

@media (min-width: 1300px) and (max-width: 1399px) {
  .main-title { font-size: 62px; line-height: 66px; }
}

@media (min-width: 1200px) and (max-width: 1299px) {
  .main-title { font-size: 62px; line-height: 66px; }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .main-title { font-size: 52px; line-height: 60px; }
}

@media (min-width: 768px) and (max-width: 991px) {
  .main-title { font-size: 40px; line-height: 44px; }
  .block-title { font-size: 28px; line-height: 34px; }
}

@media (max-width: 767px) {
  body { font-size: 18px; line-height: 28px; }
  .main-title { font-size: 34px; line-height: 36px; }
  .btn { font-size: 18px; line-height: 20px; padding: 15px 20px; }
  .btn-blue span {font-size: 20px; line-height: 20px;}
  .block-title { font-size: 24px; line-height: 32px; }
  .common-detail {font-size: 18px; line-height: 28px;}
}
/* Responsive End */